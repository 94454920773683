<template>
  <div>
    <TitleMore
      :title="['应急救护培训基地']"
      :isMore="true"
      :isDonate="true"
      :moreTitle="'红十字应急救护培训基地建设一览表>>'"
      @handleMore="handleMore('jhfw_jdylb')"
    ></TitleMore>
    <div class="baseCard serverCard">
      <div class="trainCard">
        <p style="
          font-size: 20px;
          font-weight: bold;
          color: #555454;
          line-height: 48px;
          text-align:center">应急救护培训基地简介</p>
        <TitleLine width="200px"></TitleLine>
        <div
          class="trainContentCard"
          v-html="introData.emergency_rescue_introduction"
        ></div>

      </div>
      <div
        id="myChart1"
        class="map"
      ></div>
    </div>
    <TitleMore
      :title="['景区救护站']"
      :isMore="true"
      :isDonate="true"
      :moreTitle="'红十字景区救护站建设一览表>>'"
      @handleMore="handleMore('jhfw_jqjhzylb')"
    ></TitleMore>
    <div class="baseCard serverCard">
      <div class="trainCard">
        <p style="
          font-size: 20px;
          font-weight: bold;
          color: #555454;
          line-height: 48px;
          text-align:center">景区救护站简介</p>
        <TitleLine width="200px"></TitleLine>
        <div
          class="trainContentCard"
          v-html="introData.rescue_station_introduction"
        ></div>

      </div>
      <div
        id="myChart"
        class="map"
      ></div>
    </div>
    <!-- <TitleMore
      :title="['生命安全体验馆、生命安全体验教室']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard serverCard">
      <div class="trainCard">
        <p style="
          font-size: 24px;
          font-weight: bold;
          color: #555454;
          line-height: 48px;
          text-align:center">生命安全体验馆、生命安全体验教室等简介</p>
        <TitleLine width="200px"></TitleLine>
        <div
          class="trainContentCard"
          v-html="introData.experience_hall_introduction"
        >
        </div>

      </div>
      <div
        id="myChart2"
        class="map"
      ></div>
    </div> -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="700">
      <el-scrollbar class="f-h-600">
        <div v-html="tableData.text">

        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      geoCoordMap: {},
      introData: {},
      dialogVisible:false,
      tableData:{},
      dialogTitle:''
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    // this.getList("jhfw_smaqtjg_map").then((res) => {
    //   this.drawMap2(res);
    // });
    this.getList("jhfw_yjjhpxjd_map").then((res) => {
      this.drawMap1(res);
    });
    this.getList("jhfw_jqjhz_map").then((res) => {
      this.drawMap(res);
    });
    this.getIntroduce();
  },

  methods: {
    handleMore(node){
      if(node == 'jhfw_jdylb'){
        this.dialogTitle = '红十字应急救护培训基地建设一览表'
      }else{
        this.dialogTitle = '红十字景区救护站建设一览表'
      }
      apiUrls.trainNum({ node: node }).then((res) => {
        this.tableData = res.results.data;
      });
      this.dialogVisible = true;
    },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
            detail_address:data[i].detail_address,
            publishDate:data[i].publishDate,
            link_phone:data[i].link_phone
          });
        }
      }
      return res;
    },
    drawMap1(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart1"));
      // 绘制图表
      //选项配置
      myChart.setOption({
        geo: {
          show: true,
          map: "sichuan",
          itemStyle: {
            normal: {
              areaColor: "#FBFAFA",
              shadowColor: "rgba(0, 0, 0, 0.4)",
              shadowBlur: 20,
              shadowOffsetX: -30,
              shadowOffsetY: 20,
              borderWidth: 0,
            },
            emphasis: {
              areaColor: "#2AB8FF",
              borderWidth: 0,
              color: "green",
              label: {
                show: false,
              },
            },
          },
        },
        tooltip : {
          formatter:function(params){
            if(params.componentSubType== "scatter"){
              var str = 
              `<div class="tooltipBox">
                <p style="color:#fff">名称：${params.data.name}</p>
                <p style="color:#fff">联系电话：${params.data.link_phone}</p>
                <p style="color:#fff">地址：${params.data.detail_address}</p>
              </div>`
              return str
            }
          }
        },
        series: [
          {
            name: "四川红十字会",
            type: "map",
            map: "sichuan", // 自定义扩展图表类型
          },
          {
            name: "四川红十字会",
            type: "scatter",
            coordinateSystem: "geo",
            label: {
              show: false,
              position: [-8, -15],
              formatter: (val) => {
                return val.data.name;
              },
            },
            data: this.convertData(data),
          },
        ],
      });
      // myChart.on('click', function (params) {
      //     if(params.seriesType== "scatter"){

      //     }
      // });
    },
    drawMap(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      //选项配置
      myChart.setOption({
        geo: {
          show: true,
          map: "sichuan",
          itemStyle: {
            normal: {
              areaColor: "#FBFAFA",
              shadowColor: "rgba(0, 0, 0, 0.4)",
              shadowBlur: 20,
              shadowOffsetX: -30,
              shadowOffsetY: 20,
              borderWidth: 0,
            },
            emphasis: {
              areaColor: "#2AB8FF",
              borderWidth: 0,
              color: "green",
              label: {
                show: false,
              },
            },
          },
        },
        tooltip : {
          formatter:function(params){
            if(params.componentSubType== "scatter"){
              var str = 
              `<div class="tooltipBox">
                <p style="color:#fff">名称：${params.data.name}</p>
                <p style="color:#fff">联系电话：${params.data.link_phone}</p>
                <p style="color:#fff">地址：${params.data.detail_address}</p>
              </div>`
              return str
            }
          }
        },
        series: [
          {
            name: "四川红十字会",
            type: "map",
            map: "sichuan", // 自定义扩展图表类型
          },
          {
            name: "四川红十字会",
            type: "scatter",
            coordinateSystem: "geo",
            label: {
              show: false,
              position: [-8, -15],
              formatter: (val) => {
                return val.data.name;
              },
            },
            data: this.convertData(data),
          },
        ],
      });
      myChart.on('click', function (params) {
          console.log(params);
      });
    },
    drawMap2(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart2"));
      // 绘制图表
      //选项配置
      myChart.setOption({
        geo: {
          show: true,
          map: "sichuan",
          itemStyle: {
            normal: {
              areaColor: "#FBFAFA",
              shadowColor: "rgba(0, 0, 0, 0.4)",
              shadowBlur: 20,
              shadowOffsetX: -30,
              shadowOffsetY: 20,
              borderWidth: 0,
            },
            emphasis: {
              areaColor: "#2AB8FF",
              borderWidth: 0,
              color: "green",
              label: {
                show: false,
              },
            },
          },
        },
        tooltip : {
          formatter:function(params){
            if(params.componentSubType== "scatter"){
              console.log(params)
              var str = 
              `<div class="tooltipBox">
                <p style="color:#fff">名称：${params.data.name}</p>
                <p style="color:#fff">手机：${params.data.link_phone}</p>
                <p style="color:#fff">地址：${params.data.detail_address}</p>
              </div>`
              return str
            }
          }
        },
        series: [
          {
            name: "四川红十字会",
            type: "map",
            map: "sichuan", // 自定义扩展图表类型
          },
          {
            name: "四川红十字会",
            type: "scatter",
            coordinateSystem: "geo",
            label: {
              show: true,
              position: [-8, -15],
              formatter: (val) => {
                return val.data.name;
              },
            },
            data: this.convertData(data),
          },
        ],
      });
    },
    getList(node) {
      return apiUrls.selfDynamicList({ node ,size:999}).then((res) => {
        let pointData = [];
        res.results.data.forEach((el) => {
          let obj = {
            name: el.title,
            value: null,
            detail_address:el.detail_address,
            publishDate:el.publishDate,
            link_phone:el.link_phone
          };
          pointData.push(obj);
          this.geoCoordMap[el.title] = [el.longitude, el.latitude];
        });

        return pointData;
      });
    },
    getIntroduce(node) {
      apiUrls.trainNum({ node: "jhfw" }).then((res) => {
        this.introData = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.serverCard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .trainCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
  }
  .trainContentCard {
    width: calc(100% - 80px);
    height: auto;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 0px #fad5d6;
    font-size: 16px;
    color: #666666;
    line-height: 36px;
    padding: 20px 40px;
    background: url("../../assets/img/bg-left.png") no-repeat;
    background-size: 20% 130%;
  }
  .map {
    width: 522px;
    height: 400px;
  }
}
.f-h-600{
  height: 600px;
  /deep/ .el-scrollbar__wrap{
    overflow-x: hidden;
  }
}
</style>